import { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import ToggleBox from '../FormComponents/ToggleBox.js';
import { getState } from '../Utils.js';
import BoardCanvas from './BoardCanvas.js';
import DefaultBoard from './DefaultBoard.json';

function BoardMaker() {
  const [board, setBoard] = useState(DefaultBoard);

  // given a "property path" in the board object (e.g. "range.text"),
  // returns a 2-tuple containing the value of that prop and a function to modify it.
  function boardState(propPath) {
    return getState(board, setBoard, propPath);
  }

  function setBoardProperty(propPath, newVal) {
    let setProp = boardState(propPath)[1];
    setProp(newVal);
  }

  function onBoardChange(propPath) {
    return (event) => setBoardProperty(propPath, event.target.value);
  }

  function onBoardInputChange(event) {
    setBoard(JSON.parse(event.target.value));
  }

  return (
    <>
      <Row>
        <ToggleBox title="Spirit Name">
          <Form.Control
            as="textarea"
            placeholder="Add a spirit name here..."
            value={board.spiritName}
            onChange={onBoardChange("spiritName")}
          />
        </ToggleBox>
      </Row>
      <BoardCanvas board={board}></BoardCanvas>
      <ToggleBox title="Board State">
        <pre>
          <Form.Control as="textarea" value={JSON.stringify(board, null, 2)} onChange={onBoardInputChange} style={{ height: '8000px' }} />
        </pre>
      </ToggleBox>
    </>
  )
}

export default BoardMaker;