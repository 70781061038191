import { useState } from "react";
import { Form, Image as BootstrapImage, InputGroup } from "react-bootstrap";
import { getImageFromSource, getImageFromUrl, getImgSrc } from "../Utils";

function ImageForm({ setImage }) {
  const [imageFormUrl, setImageFormUrl] = useState("");
  const [imageDownloadError, setImageDownloadError] = useState(null);
  const [imageUploadError, setImageUploadError] = useState(null);

  function onImageFileChanged(event) {
    setImageUploadError(null);
    const selectedFile = event.target?.files[0];
    if (!selectedFile) // user hit "cancel" in file upload menu
      return;

    getImageFromSource(selectedFile)
      .then(setImage)
      .catch(() => setImageUploadError("Error uploading image. Double-check the file type; we support most common image files, including JPG, PNG, and SVG."));
  }

  function onImageUrlChanged(event) {
    let newImageUrl = event.target.value;
    setImageFormUrl(newImageUrl);
    setImageDownloadError(null);

    getImageFromUrl(newImageUrl)
      .then(setImage)
      .catch(() => setImageDownloadError("Error loading image. Check the URL, or try downloading it and submitting it via file upload instead."));
  }

  return (
    <>
      <Form.Control type="file" onChange={onImageFileChanged} />
      {imageUploadError}
      <BootstrapImage src={getImgSrc("icon_wide_or")}
        style={{ display: "block", width: "40%", maxWidth: "200px", margin: "10px auto" }}
      />
      <InputGroup>
        <InputGroup.Text>From URL</InputGroup.Text>
        <Form.Control onChange={onImageUrlChanged} value={imageFormUrl} placeholder={"Enter image url here"} />
      </InputGroup>
      {imageDownloadError}
    </>
  );
}

export default ImageForm;