export function getImgSrc(imageName) {
  if (!window.SpiritForge.images[imageName]) return;
  return window.SpiritForge.images[imageName].currentSrc;
}

export function getImg(imageName) {
  return window.SpiritForge.images[imageName];
}

export async function getImageFromUrl(url) {
  return fetch(url)
    .then(response => {
      if (!response.ok)
        throw Error(response.statusText);
      return response.blob();
    })
    .then(getImageFromSource);
}

export async function getImageFromSource(source) {
  return readData(source).then(getImageFromBase64);
}

function readData(source) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = () => reject(Error("FileReader error."));
    reader.readAsDataURL(source);
  });
}

export function getImageFromBase64(imageSrc) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => resolve(image);
    image.onerror = () => reject(Error("Image Load error."))
    image.src = imageSrc;
  });
}

/**
 * Gets a state tuple for a specific property nested in some larger object. Example:
 * 
 * card: {
 *   range: {
 *     enabled: true,
 *     text: "rangeVal"
 *   }
 * }
 * getState(card, setCard, "range.text") returns ["rangeVal", functionX]
 * where functionX takes a value and uses setCard to update card.range.text to that value.
 * 
 * obj and setObj should be from a useState call (or derived from one).
 * propPathStr should be a "path" to some property in the object.
 * if we don't find a property by following it, we throw a custom error.
*/
export function getState(obj, setObj, propPathStr) {
  let propPath = propPathStr.split(".");

  // these are arrays of references to "intermediate" objects and setObject functions
  // on the way to the final, fully nested ones. I tried to just have one reference each that I
  // updated in the while loop, but that didn't seem to work, I suspect for pointer-y reasons.
  let objRefs = [obj];
  let setObjFunctionRefs = [setObj];

  while (propPath.length > 0) {
    const currObj = lastElem(objRefs);
    const setObj = lastElem(setObjFunctionRefs);
    const currPropName = propPath.shift();

    if (!currObj.hasOwnProperty(currPropName)) {
      throw new Error(`Invalid getState call for property path "${propPathStr}". Could not find "${currPropName}".`);
    }

    setObjFunctionRefs.push((newVal) => {
      let newObj = Object.assign({}, currObj);
      newObj[currPropName] = newVal;
      setObj(newObj);
    });
    objRefs.push(currObj[currPropName]);
  }

  return [lastElem(objRefs), lastElem(setObjFunctionRefs)];
}

export function lastElem(arr) {
  return arr[arr.length - 1];
}

// it actually seems like the best solution for file downloads on stackoverflow is this
// weird "add an html element and pretend to click it" thing. slightly horrifying.
export function downloadJSON(object, fileName) {
  let file = new Blob([JSON.stringify(object, null, 2)], { type: "json" });
  if (window.navigator.msSaveOrOpenBlob) { // IE10+
    // should test this at some point?
    window.navigator.msSaveOrOpenBlob(file, fileName);
  } else { // Others
    let a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}