import { Table } from "react-bootstrap";
import { getImg, getImgSrc } from "../Utils";

function SymbolList({symbolsList}) {
  const symbols = symbolsList;

  return (
    <Table striped bordered hover size="sm">
      <thead>
      </thead>
      <tbody>
        {Object.keys(symbols).map((symbolName, index) => {
          let img = getImg("icon_" + symbolName);
          let symbolData = symbols[symbolName];
          if (!img) return <></>;
          if (!symbolData.list) return <></>;
          let notes = symbolData.notes;
          if (!notes) notes = "";
          return (
            <tr height="75px" key={index}>
              <td style={{ textAlign: "center", verticalAlign: "middle" }}>{"{" + symbolName + "}"}</td>
              <td style={{ textAlign: "center", verticalAlign: "middle" }}><img src={getImgSrc("icon_" + symbolName)} width={img.width * symbolData.list} height={img.height * symbolData.list} alt={symbolName}></img></td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
}

export default SymbolList;