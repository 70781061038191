import { Container } from "react-bootstrap";
import ToggleBox from "../FormComponents/ToggleBox";
import './InfoPage.css';

function InfoPage() {
    const publisherTerms = "\"Do what you like, so long it doesn't involve any of the following:\n" +
    "* Charging money for it\n" +
    "* Portraying it as an official Spirit Island product\n" +
    "* Posting it on the official greaterthangames.com forums\n" +
    " We will not under any circumstances look at submissions of such things, and will delete any posts on greaterthangames.com which contain them.\"";

    const designerTerms = "\"Please respect the publisher's desires.\n" +
    "I'd quite enjoy being able to peruse people's ideas for Spirit Island game elements, but have been advised that I only ought to do so when the creator of said ideas agrees to the following:\n" +
    "* I am under no obligation to look at your ideas or to reply to your post/communication. (It can be fun, but life is often busy.)\n" +
    "* You are owed no acknowledgement for your ideas, even if something similar, identical, or derivative is later published. (It's entirely possible that I was already working on something similar - parallel inspiration happens more often than you might think. And I don't want to have to track the source of every single idea I ever read online just in case it plays into some later idea I have; that's a lot of work!)\n" +
    "* You are owed no compensation of any sort for your ideas: even if something similar, identical, or derivative is later published; even if your idea is acknowledged as a source or inspiration; even if you did lots of playtesting work on it. If relevant, you assign any and all rights / creative control over your Spirit Island ideas to me. (So I can run with them if need be. If you're not OK with this, I need to avoid reading your ideas so I'm not constantly second-guessing myself during development of my own ideas!)\"";

    const publisherTermsJsx = publisherTerms.split("\n").map((text) => 
      <><span><i>{text}</i></span><br /></>
    );

    const designerTermsJsx = designerTerms.split("\n").map((text) => 
    <><span><i>{text}</i></span><br /></>
  );

    return (
        <Container className="infopage" fluid>
            <ToggleBox title="Fan Content Policy" variant="secondary">
              <span>The Spirit Island FAQ lays out <a href="https://querki.net/u/darker/spirit-island-faq/#!.9v5ka4u" target="_blank" rel="noreferrer">guidelines</a> for creating and sharing custom game elements.</span>
              <hr />
              <span><b>The publisher has the following position:</b></span><br />
              {publisherTermsJsx}
              <hr />
              <span><b>The designer, R. Eric Reuss, has the following position:</b></span><br />
              {designerTermsJsx}
              <hr />
              Please ensure that content you create and share using this website is in accordance with these terms.
            </ToggleBox>
        </Container>
    );
}

export default InfoPage;