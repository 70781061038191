
import Papa from 'papaparse';
import React from 'react';
import { Card, Container, Image as ImageCmpt, Modal, Spinner } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router';
import BoardMaker from './BoardMaker/BoardMaker';
import CardMaker from './CardMaker/CardMaker';
import InfoPage from './InfoPage/InfoPage';
import loadingImage from './loading-bg.png';
import NavBar from './NavBar';
import symbolSizeData from './SymbolSizeSpreadsheet.csv';
import TutorialPage from './TutorialPage/TutorialPage';

class App extends React.Component {
  constructor(props) {
    super(props);

    // this is just in the constructor so we can set the initial state.numImagesToLoad correctly.
    this.imageRefs = this.getRefsToImagesToLoad();
    this.state = {
      numImagesToLoad: Object.keys(this.imageRefs).length,
      spreadsheetLoaded: false
    };
    this.decrementNumImagesToLoad = this.decrementNumImagesToLoad.bind(this);
  }

  /**
   * webpack voodoo to import all images from a directory. we run this just once, in the constructor.
   * https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
   */
  getRefsToImagesToLoad() {
    const webpackContext = require.context('./images', true, /\.(png)$/); // "true" means "use subdirectories".
    let imageRefs = {};
    for (const imageFileName of webpackContext.keys()) {
      // store image name without file path and ".png"
      const slashIndex = imageFileName.lastIndexOf('/');
      const imageName = imageFileName.substring(slashIndex + 1, imageFileName.length - 4);
      imageRefs[imageName] = webpackContext(imageFileName); // no idea why webpackContext is a function, but this works
    }

    return imageRefs;
  }

  /**
   * create Image objects for all our assets, and add them to global memory via window.
   * add event listeners to keep track of when they're finished loading, and don't try to render
   * any components that use images until they're all done. (we put a loading screen over everything instead.)
   * 
   * seems like we should be using something like redux for global state like this,
   * but using react for a lil hobby project was overkill enough already. this seems hacky but reasonable.
   * 
   * we wait until component mount to do this, because we need to update the state of this component
   * with the number of images that have finished loading, and we can't access the state before mount.
   * https://reactjs.org/docs/react-component.html#the-component-lifecycle
   */
  componentDidMount() {
    window.SpiritForge = {};

    let images = {};
    for (const ref of Object.keys(this.imageRefs)) {
      let loadingImage = new Image();
      loadingImage.onload = this.decrementNumImagesToLoad;
      loadingImage.src = this.imageRefs[ref].default;
      images[ref] = loadingImage;
    }

    fetch(symbolSizeData)
      .then(response => response.text())
      .then(data => {
        let symbolSizes = this.buildSymbolSizes(data);
        window.SpiritForge.symbolSizes = symbolSizes;
        this.setState({ spreadsheetLoaded: true });
      });

    window.SpiritForge.images = images;
  }

  buildSymbolSizes(data) {
    var results = Papa.parse(data, { header: true });

    var symbolSizes = {};
    for (let i = 0; i < results.data.length; i++) {
      var entry = results.data[i];
      symbolSizes[entry.name] = {};
      var fields = ["card", "board", "presence", "growth", "list", "notes"];
      for (let j = 0; j < fields.length; j++) {
        var field = fields[j];
        if (field === "notes") {
          symbolSizes[entry.name][field] = entry[field];
        } else {
          symbolSizes[entry.name][field] = parseFloat(entry[field]);
        }
      }
    }

    return symbolSizes;
  }

  decrementNumImagesToLoad() {
    this.setState({ numImagesToLoad: this.state.numImagesToLoad - 1 });
  }

  appIsLoading() {
    return this.state.numImagesToLoad > 0 || !this.state.spreadsheetLoaded;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // no need to rerender until everything is loaded
    return this.appIsLoading();
  }

  render() {
    return (
      <>
        <Card.Header className="text-muted text-center app-header">
          <div>This is an unofficial website. SpiritForge is not affiliated with Greater Than Games, LLC. All materials belong to Greater Than Games, LLC.</div>
          {/* <div className="ms-auto">Buy us a coffee! <a href="https://ko-fi.com/spiritforge">ko-fi.com/spiritforge</a></div> */}
        </Card.Header>
        <NavBar />
        <Modal show={this.appIsLoading()} backdrop="static" keyboard={false} centered>
          <Modal.Header style={{ justifyContent: "space-evenly" }}>
            <Spinner animation="border" variant="primary" />
            <Modal.Title>Loading SpiritForge Assets...</Modal.Title>
          </Modal.Header>
          <Modal.Body>This could take a moment, depending on your connection.</Modal.Body>
        </Modal>
        <Container className="app-container" fluid>
          {this.appIsLoading() ? <ImageCmpt src={loadingImage} fluid /> :
            <Routes>
              <Route exact path="/" element={<Navigate to="/cards" />} />
              <Route path="/cards" element={<CardMaker />} />
              <Route path="/boards" element={<BoardMaker />} />
              <Route path="/tips" element={<TutorialPage />} />
              <Route path="/policy" element={<InfoPage />} />
            </Routes>
          }
        </Container>
        <Card.Footer className="text-muted text-center app-footer">
          <div>© SpiritForge Developers, 2021. Contact: devs@<span style={{ display: "none" }}>null</span>spirit<span style={{ display: "none" }}>null</span>forge.app</div>
        </Card.Footer>
      </>
    );
  }
}

export default App;