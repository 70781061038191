import { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import SymbolList from './SymbolList';

function SymbolListPopup() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" size="lg" onClick={handleShow} style={{ width: "50%" }}>
        Open Icon Reference
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement={"end"} style={{ width: "fit-content" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Symbol List</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SymbolList symbolsList={window.SpiritForge.symbolSizes} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SymbolListPopup;