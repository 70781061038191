import { Alert, Form } from "react-bootstrap";

function SpeedForm(props) {
  const [speed, setSpeed] = props.speedState;


  function onSpeedChanged(event) {
    setSpeed(event.target.value);
  }

  return (
    <Alert
      style={{ "minHeight": "150px" }}>
      <Alert.Heading>Speed</Alert.Heading>
      <Form.Select value={speed} onChange={onSpeedChanged}>
        <option value="fast">Fast</option>
        <option value="slow">Slow</option>
      </Form.Select>
    </ Alert>
  );
}

export default SpeedForm;