import { Badge, Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moon from './moon250.png';

function NavBar() {

  const linkStyle = {
    margin: "0.5rem",
    textDecoration: "none",
    color: 'white',
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">    
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={moon}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          SpiritForge
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/cards" style={linkStyle}>Card Maker <Badge>Beta</Badge></Link>
            {/* <Link to="/boards" style={linkStyle}>Board Maker <Badge bg="secondary">Alpha</Badge></Link> */}
            <Link to="/tips" style={linkStyle}>Tips</Link>
            <Link to="/policy" style={linkStyle}>Policy</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
