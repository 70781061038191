import React, { useEffect, useRef } from "react";
import { clearCanvas, createCanvasGlob, createCardArea, drawImageInArea, drawVariableHeightArea, parseAndWriteTextInArea, simpleDraw } from '../CanvasUtils.js';
import { FONT_STYLES } from '../FontStyles.js';
import { CARD_LOCALIZATIONS } from '../CardElementsLocalization.js'
import { getImg } from "../Utils.js";

function CardCanvas({ card, ...rest }) {
  const ratio = 1.35341830823;
  const scale = 500;
  const symbolSizeRatio = 1 / 850;

  const images = window.SpiritForge.images; // see App.js

  const canvasRef = useRef();
  useEffect(renderCard);

  // this is what the CardCanvas functional component ultimately returns.
  // all the logic for actually drawing stuff in it is below.
  function cardCanvasElement() {
    return <canvas ref={canvasRef} width={scale} height={scale * ratio} {...rest} />
  }

  function renderCard() {
    const canvasGlob = createCanvasGlob(canvasRef.current, images, scale, ratio, symbolSizeRatio, "card");

    // wipe existing stuff
    clearCanvas(canvasGlob);

    // first draw the card art
    const artArea = createCardArea(canvasGlob, 0.584, 0.3315, 0.73, 0.38);
    if (card.art.image != null) {
      let maxLeftOffsetPx = card.art.image.width / 2 + artArea.px_width / 2;
      let maxTopOffsetPx = card.art.image.height / 2 + artArea.px_height / 2;
      let leftOffsetPx = card.art.leftOffset * maxLeftOffsetPx;
      let topOffsetPx = card.art.topOffset * maxTopOffsetPx;
      drawImageInArea(canvasGlob, card.art.image, artArea, 0.5 + leftOffsetPx / artArea.px_width, 0.5 + topOffsetPx / artArea.px_height, card.art.scale);
    }

    // draw the frame, on top of the art
    simpleDraw(canvasGlob, "empty_card");

    // draw the fast/slow cost and speed symbol
    simpleDraw(canvasGlob, card.speed);

    // draw the cost
    const globalArea = createCardArea(canvasGlob, 0.5, 0.5, 1.0, 1.0, null);
    parseAndWriteTextInArea(canvasGlob, card.cost.toString(), FONT_STYLES.CARDCOST, globalArea, 0.1485, 0.093, 1.0);

    // find which elements are checked and draw those 
    var elements = ["sun", "moon", "fire", "air", "water", "earth", "plant", "animal"];
    for (let i = 0; i < elements.length; i++) {
      if (card.elements[elements[i]]) {
        simpleDraw(canvasGlob, "element_" + elements[i]);
      }
    }

    // register card name area and draw card name
    var nameArea = createCardArea(canvasGlob, 0.6, 0.087, 0.7, 0.105);
    parseAndWriteTextInArea(canvasGlob, card.name.toUpperCase(), FONT_STYLES.CARDNAME, nameArea, 0.5, 0.5, card.nameFontScale, true);

    // draw the 'target, speed' bar
    if (card.language === "English") {
      simpleDraw(canvasGlob, "bar");
      simpleDraw(canvasGlob, card.range.enabled ? "target_land" : "target");
    } else {
      simpleDraw(canvasGlob, "empty_bar");
      parseAndWriteTextInArea(canvasGlob, CARD_LOCALIZATIONS[card.language][0], FONT_STYLES.CARDBAR, globalArea, 0.3225, 0.51, 1.0);
      parseAndWriteTextInArea(canvasGlob, CARD_LOCALIZATIONS[card.language][1], FONT_STYLES.CARDBAR, globalArea, 0.5675, 0.51, 1.0);
      let targetToWriteIndex = card.range.enabled ? 2 : 3;
      parseAndWriteTextInArea(canvasGlob, CARD_LOCALIZATIONS[card.language][targetToWriteIndex], FONT_STYLES.CARDBAR, globalArea, 0.82, 0.51, 1.0);
    }

    // draw card range or none
    var rangeArea = createCardArea(canvasGlob, 0.57, 0.553, 0.25, 0.064)
    if (card.range.enabled) {
      parseAndWriteTextInArea(canvasGlob, card.range.text, FONT_STYLES.THRESHOLDCONDITION, rangeArea, 0.5, 0.5, 1.0);
    } else {
      simpleDraw(canvasGlob, "range_none");
    }

    // draw card target or none
    var targetArea = createCardArea(canvasGlob, 0.823, 0.553, 0.25, 0.064);
    if (card.target.enabled) {
      // simpleDraw(canvasGlob, card.target);
      parseAndWriteTextInArea(canvasGlob, card.target.text, FONT_STYLES.TARGET, targetArea, 0.5, 0.5, 1.0);
    } else {
      simpleDraw(canvasGlob, "target_none");
    }

    // draw effect, and threshold if enabled
    var effectArea = createCardArea(canvasGlob, 0.584, 0.765, 0.73, 0.36);
    if (card.threshold.enabled) {
      // do some math to calculate the relative size of the card effect area, the threshold text area, etc.
      var thresholdRelativeHeight = card.threshold.relativeHeight;
      var effectRelativeHeight = 1 - thresholdRelativeHeight;
      var thresholdLabelGap = 0.09;
      var thresholdConditionTextHeight = 0.15;
      var thresholdEffectTextHeight = thresholdRelativeHeight - thresholdLabelGap - thresholdConditionTextHeight;

      var cardEffectTextArea = createCardArea(canvasGlob, 0.5, effectRelativeHeight / 2, 1, effectRelativeHeight, effectArea);
      var thresholdArea = createCardArea(canvasGlob, 0.5, effectRelativeHeight + thresholdRelativeHeight / 2, 1, thresholdRelativeHeight, effectArea);
      var thresholdConditionTextArea = createCardArea(canvasGlob, 0.5, effectRelativeHeight + thresholdLabelGap + thresholdConditionTextHeight / 2, 1, thresholdConditionTextHeight, effectArea);
      var thresholdEffectTextArea = createCardArea(canvasGlob, 0.5, effectRelativeHeight + thresholdLabelGap + thresholdConditionTextHeight + thresholdEffectTextHeight / 2, 1, thresholdEffectTextHeight, effectArea);

      // parse and write card effect text into the squished area above the threshold instead of effectArea
      parseAndWriteTextInArea(canvasGlob, card.effect, FONT_STYLES.EFFECT, cardEffectTextArea, 0.5, 0.5, card.effectFontScale, true);

      // draw in the card threshold area
      if (card.language === "English") {
        drawVariableHeightArea(canvasGlob, "element_threshold_area", thresholdArea, thresholdRelativeHeight);
      } else {
        drawVariableHeightArea(canvasGlob, "empty_threshold_area", thresholdArea, thresholdRelativeHeight);
        parseAndWriteTextInArea(canvasGlob, "*" + CARD_LOCALIZATIONS[card.language][4] + "*", FONT_STYLES.IFYOUHAVE, effectArea, 0.505, 1.03 - thresholdRelativeHeight, 1.0);
      }

      // parse and write element threshold text into element threshold text area
      parseAndWriteTextInArea(canvasGlob, card.threshold.conditionText + "{colon}", FONT_STYLES.THRESHOLDCONDITION, thresholdConditionTextArea, 0.5, 0.5, 1.0);

      // parse and write threshold effect text into its area
      parseAndWriteTextInArea(canvasGlob, card.threshold.effectText, FONT_STYLES.EFFECT, thresholdEffectTextArea, 0.5, 0.5, card.effectFontScale, true);

      // debug rectangles for threshold-related areas
      // draw_areas(canvasGlob, [cardEffectTextArea, thresholdArea, thresholdConditionTextArea, thresholdEffectTextArea]);
    } else {
      // parse and write card effect text into the full effect area
      parseAndWriteTextInArea(canvasGlob, card.effect, FONT_STYLES.EFFECT, effectArea, 0.5, 0.5, card.effectFontScale, true);
    }

    // draw artist name
    var artistArea = createCardArea(canvasGlob, 0.584, 0.958, 0.73, 0.024);
    parseAndWriteTextInArea(canvasGlob, card.art.artist.toUpperCase(), FONT_STYLES.ARTISTNAME, artistArea, 0.06, 0.56, 1.0);

    // draw meeple
    if (card.meeple) {
      drawImageInArea(canvasGlob, getImg("meeple"), null, 0.925, 0.959, 0.4);
    }

    // debug rectangles for areas
    // draw_areas(canvasGlob, [nameArea, effectArea, rangeArea, targetArea, artistArea]);
  }

  return cardCanvasElement();
};

export default CardCanvas;