import { Col, Row } from 'react-bootstrap';
import { getImgSrc } from '../Utils';
import './ElementShelf.css';
import ImageToggle from './ImageToggle';

const ELEMENTS = {
  sun: "simple_sun",
  moon: "simple_moon",
  fire: "simple_fire",
  air: "simple_air",
  water: "simple_water",
  earth: "simple_earth",
  plant: "simple_plant",
  animal: "simple_animal",
}

function ElementShelf(props) {
  const [elements, setElements] = props.elementState;

  function toggleElement(elementName) {
    var newElements = { ...elements }; // shallow copy via spread operator works fine here
    newElements[elementName] = !elements[elementName];
    setElements(newElements);
  }

  return (
    <>
      {Object.entries(ELEMENTS).map(([elementName, elementImg]) =>
        <Row key={elementName}>
          <Col>
            <ImageToggle
              src={getImgSrc(elementImg)}
              toggled={elements[elementName]}
              onToggle={() => toggleElement(elementName)}
              className="shelfelement" />
          </Col>
        </Row>
      )}
    </>
  );
}

export default ElementShelf;