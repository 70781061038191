var defaultStyle = {
  font: "Josefin Sans", // font name
  color: "black", // font color
  letterSpacingRatio: 0, // px between letters per scale
  normalWeight: "400",
  boldWeight: "900", // prefix for boldface
  italicStyle: "italic", // prefix for italics
  normalStyle: "normal", // prefix for bold italics
  sizeRatio: 20 / 400, // relative font size scalar
  lineSpacingRatio: 1.0, // line spacing relative to font size
  textAlignment: "center", // text horizontal alignment 
  textBaseline: "middle", // text vertical alignment
  verticalCorrectionRatio: 0.1, // ratio of sizeRatio to shift to account for bad middle alignment 
  autoCaps: false
};
var fontStylesDefault = {
  CARDNAME:
  {
    font: "DK Snemand",
    color: "white",
    sizeRatio: 24 / 400,
    lineSpacingRatio: 1.0,
    letterSpacingRatio: 1 / 300,
  },
  EFFECT:
  {
    letterSpacingRatio: -1 / 500,
    lineSpacingRatio: 1.25
  },
  ARTISTNAME:
  {
    color: "white",
    letterSpacingRatio: 0,
    sizeRatio: 9 / 400,
    textAlignment: "left"
  },
  THRESHOLDCONDITION:
  {
    font: "Gobold Extra2",
    verticalCorrectionRatio: 0
  },
  RANGE:
  {
    font: "Antropos Freefont",
    sizeRatio: 24 / 400,
    verticalCorrectionRatio: -0.05 // used to upshift range number
  },
  CARDCOST:
  {
    font: "Gobold Extra2",
    color: "white",
    sizeRatio: 27 / 400
  },
  SPECIAL_RULES_TITLE:
  {
    font: "Lato",
    sizeRatio: 23.5 / 2000,
    lineSpacingRatio: 1.25,
    normalWeight: "900",
    textBaseline: "top",
    textAlignment: "left"
  },
  SPECIAL_RULES_TEXT:
  {
    letterSpacingRatio: -2 / 2000,
    lineSpacingRatio: 1.25,
    sizeRatio: 28 / 2000,
    textBaseline: "top",
    textAlignment: "left",
    normalWeight: "400",
  },
  INNATE_THRESHOLD:
  {
    sizeRatio: 28 / 2000,
    textAlignment: "left",
    font: "Gobold Extra2",
    verticalCorrectionRatio: 0,
    letterSpacingRatio: 0
  },
  LATO_LABEL:
  {
    sizeRatio: 22 / 2000,
    textBaseline: "top",
    font: "Lato",
    normalWeight: "700",
    letterSpacingRatio: 0.2 / 2000,
    verticalCorrectionRatio: 0
  },
  ENERGY:
  {
    sizeRatio: 0.16,
    font: "Gobold Extra2",
    verticalCorrectionRatio: 0
  },
  GROWTH_RANGE:
  {
    sizeRatio: 0.055,
    font: "Antropos Freefont",
    verticalCorrectionRatio: -0.05
  },
  PRESENCE_RANGE:
  {
    sizeRatio: 0.18,
    font: "Antropos Freefont",
    verticalCorrectionRatio: .04
  },
  SPIRIT_NAME:
  {
    sizeRatio: 0.024,
    font: "DK Snemand",
    textAlignment: "left",
    color: "white",
    strokeColor: "black",
    letterSpacingRatio: 1 / 700,
  },
  TARGET:
  {
    font: "DK Snemand",
    color: "black",
    sizeRatio: 16 / 400,
    lineSpacingRatio: 1.0,
    letterSpacingRatio: 0,
    autoCaps: true
  },
  CARDBAR:
  {
    font: "DK Snemand",
    color: "white",
    sizeRatio: 12/400,
    letterSpacingRatio: 1/300,
    autoCaps: true
  },
  IFYOUHAVE:
  {
    font: "DK Snemand",
    color: "#39220F",
    sizeRatio: 11 / 400,
    lineSpacingRatio: 1.0,
    letterSpacingRatio: 1/300,
  },
};


for (var style in fontStylesDefault) {
  for (const [key, value] of Object.entries(defaultStyle)) {
    if (!(key in fontStylesDefault[style])) {
      fontStylesDefault[style][key] = value;
    }
  }
}

const FONT_STYLES = fontStylesDefault;

export { FONT_STYLES };
