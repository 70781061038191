import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CostCircle from '../FormComponents/CostCircle';
import DropdownForm from '../FormComponents/DropdownForm';
import ElementShelf from '../FormComponents/ElementShelf';
import ImageForm from '../FormComponents/ImageForm';
import PercentSliderForm from '../FormComponents/SliderForm';
import SpeedForm from '../FormComponents/SpeedForm';
import SymbolListPopup from '../FormComponents/SymbolListPopup';
import ToggleBox from '../FormComponents/ToggleBox';
import ToggleForm from '../FormComponents/ToggleForm';
import { getState } from '../Utils';
import CardCanvas from './CardCanvas';
import CardIOForm from './CardIOForm';
import DefaultCard from './DefaultCard.json';

function CardMaker() {
  const [card, setCard] = useState(DefaultCard);

  // given a "property path" in the card object (e.g. "range.text"),
  // returns a 2-tuple containing the value of that prop and a function to modify it.
  function cardState(propPath) {
    return getState(card, setCard, propPath);
  }

  function setCardProperty(propPath, newVal) {
    let setProp = cardState(propPath)[1];
    setProp(newVal);
  }

  function onCardChange(propPath) {
    return (event) => setCardProperty(propPath, event.target.value);
  }

  return (
    <>
      <Row className="justify-content-md-center">
        <Col xs="auto">
          <CostCircle cost={card.cost} speed={card.speed} />
          <ElementShelf elementState={cardState("elements")} />
        </Col>
        <Col xs={10} xl>
          <Row className="align-self-stretch">
            <Col xs={3}>
              <ToggleBox title="Cost">
                <Form.Control
                  type="number"
                  value={card.cost}
                  onChange={onCardChange("cost")}
                />
              </ToggleBox>
            </Col>
            <Col>
              <ToggleBox title="Name">
                <Row>
                  <Col>
                    <Form.Control
                      as="textarea"
                      placeholder="Add a card name here..."
                      value={card.name}
                      onChange={onCardChange("name")}
                    />
                  </Col>
                  <Col >
                    <PercentSliderForm label="Font Scale" range={[50, 125]} valueState={cardState("nameFontScale")} />
                  </Col>
                </Row>
              </ToggleBox>
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleBox title="Card Art" minHeight={"315px"}>
                <ImageForm setImage={cardState("art.image")[1]} />
                <hr style={{ marginBottom: "24px" }} />
                <h5>Artist Credit</h5>
                <Form.Control
                  value={card.art.artist}
                  onChange={onCardChange("art.artist")}
                />
              </ToggleBox>
            </Col>
            <Col>
              <ToggleBox title="Image Position" minHeight={"315px"}>
                <PercentSliderForm label="Horizontal Offset" range={[-100, 100]} valueState={cardState("art.leftOffset")} />
                <PercentSliderForm label="Vertical Offset" range={[-100, 100]} valueState={cardState("art.topOffset")} />
                <PercentSliderForm label="Scale" range={[1, 200]} valueState={cardState("art.scale")} />
              </ToggleBox>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <SpeedForm speedState={cardState("speed")} />
            </Col>
            <Col>
              <ToggleBox title="Range"
                toggleState={cardState("range.enabled")}
              >
                <Form.Control
                  as="textarea"
                  placeholder="Ex: '{range,2}'"
                  value={card.range.text}
                  onChange={onCardChange("range.text")}
                />
              </ToggleBox>
            </Col>
            <Col>
              <ToggleBox
                title="Target"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Ex: '{any_land}'"
                  value={card.target.text}
                  onChange={onCardChange("target.text")}
                />
              </ToggleBox>
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleBox title="Effect">
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Ex: 'Add 1 {dahan}.'"
                  value={card.effect}
                  onChange={onCardChange("effect")}
                />
                <PercentSliderForm label="Font Scale" range={[50, 125]} valueState={cardState("effectFontScale")} />
              </ToggleBox>
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleBox title="Threshold Condition"
                toggleState={cardState("threshold.enabled")}
                minHeight={"215px"}
              >
                <Form.Control
                  as="textarea"
                  placeholder="Ex: '1{sun} 4{water}'"
                  value={card.threshold.conditionText}
                  onChange={onCardChange("threshold.conditionText")}
                />
                <PercentSliderForm label="Threshold Height" range={[0, 100]} valueState={cardState("threshold.relativeHeight")} />
              </ToggleBox>
            </Col>
            <Col>
              <ToggleBox title="Threshold Effect"
                toggleState={cardState("threshold.enabled")}
                minHeight={"215px"}
              >
                <Form.Control
                  as="textarea"
                  placeholder="Ex: 'Instead, add 2 {dahan}.'"
                  value={card.threshold.effectText}
                  onChange={onCardChange("threshold.effectText")}
                />
                {/*<PercentSliderForm label="Font Scale" range={[1,200]} valueState={cardState("effectFontScale")}/>*/}
              </ToggleBox>
            </Col>
          </Row>
        </Col>
        <Col style={{ maxWidth: '520px' }}>
          <Row className="justify-content-md-center">
            <SymbolListPopup />
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <CardCanvas card={card} />
          </Row>
          <br />
          <Row>
            <Col xs={6}>
              <ToggleBox title="Language" minHeight={"150px"}>
                <DropdownForm valueState={cardState("language")} options={["English", "German"]} />
              </ToggleBox>
            </Col>
            <Col xs={6}>
              <ToggleBox title="Meeple" minHeight={"150px"}>
                <ToggleForm toggleState={cardState("meeple")} />
                Recommended to distinguish custom content.
              </ToggleBox>
            </Col>
          </Row>
          <CardIOForm cardState={[card, setCard]} />
        </Col>
      </Row>
    </>
  )
}

export default CardMaker;