
import './CostCircle.css';

function CostCircle(props) {
  const { cost, speed, ...rest } = props;
  var costCircleClass = "costcircle";
  if (speed === "fast") {
    costCircleClass += " costcircle--fast"
  } else if (speed === "slow") {
    costCircleClass += " costcircle--slow"
  }

  return <div className={costCircleClass + " justify-content-center"} {...rest}>
    {cost}
  </div>
}

export default CostCircle;
