const SYMBOL_GROUP = {
    "single":
    {
        type: "simple",
        symbols:
        [
            {
                value: "Simple Label",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.5
            },
            {
                value: "{energy,1}",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.5
            }
        ]
    },
    "double_separate":
    {
        type: "double separate",
        symbols:
        [
            {
                value: "Double Separate Label",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.5
            },
            {
                value: "{earth}",
                scale: 1.0,
                relativeLeft: 0.33,
                relativeTop: 0.33
            },
            {
                value: "{any}",
                scale: 1.0,
                relativeLeft: 0.66,
                relativeTop: 0.66
            }
        ]
    },
    "double_overlap":
    {
        type: "double separate",
        symbols:
        [
            {
                value: "Double overlap label",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.5
            },
            {
                value: "{earth}",
                scale: 1.0,
                relativeLeft: 0.4,
                relativeTop: 0.4
            },
            {
                value: "{any}",
                scale: 1.0,
                relativeLeft: 0.6,
                relativeTop: 0.6
            }
        ]
    },
    "range_arrow":
    {
        type: "range arrow",
        width_override: 1.26,
        symbols:
        [
            {
                value: "Range arrow label",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.5
            },
            {
                value: "{range,0}",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.75
            },
            {
                value: "{jw}",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.19
            },
            {
                value: "+{presence}",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: -0.05
            },
        ]
    },
    "move_arrow":
    {     
        type: "move arrow",
        width_override: 1.2,
        symbols:
        [
            {
                value: "Move arrow label",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.5
            },
            {
                value: "{move_range,0}",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.85
            },
            {
                value: "{presence}",
                scale: 1.0,
                relativeLeft: 0.5,
                relativeTop: 0.1
            }
        ]
    }
};

export { SYMBOL_GROUP }