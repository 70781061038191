import { Container } from "react-bootstrap";
import ToggleBox from "../FormComponents/ToggleBox";
import './TutorialPage.css';

function TutorialPage() {
    return (
        <Container className="tutorialpage" fluid>
            <ToggleBox title="Tips & Tricks" variant="secondary">
                <span>Here are some tips that will help you use this website.</span>
                <hr />
                <h6>Icon Reference</h6>
                <span>Be sure to check the icon reference by hitting the "Open Icon Reference" button in the top right of the card maker.</span>
                <hr />
                <h6>Range From Something</h6>
                <span>To make a card with range "from" something, use two separate symbols. For example, <code>&#123;from_sacredsite&#125;</code><code>&#123;range,1&#125;</code> or <code>&#123;wetland_with_presence&#125;</code><code>&#123;range_2&#125;</code>.</span>
                <hr />
                <h6>Parameters</h6>
                <span>By using a comma after a symbol name, you can customise some symbols. The important ones to know are:</span>
                <ul>
                    <li>Add "no" after a symbol name to cross it out with a red X. 
                    <br/><i>Examples</i>: <code>&#123;blight,no&#125;</code> or <code>&#123;invaders,no&#125;</code></li>
                    <li>Some symbols support numerical parameters.
                    <br/><i>Examples</i>: <code>&#123;range,1&#125;</code> or <code>&#123;range,+3&#125;</code></li>
                    <li>You can add a presence marker to a symbol with "presence", however it's recommended to stick with the "_with_presence" icons if possible.
                    <br/><i>Examples</i>: <code>&#123;wetland,presence&#125;</code> is similar to <code>&#123;wetland_with_presence&#125;</code>. You can also try <code>&#123;mountain_wetland,presence&#125;</code>.
                    </li>
                </ul>
                <hr />
                <h6>Target Type</h6>
                <span>"Target Land" automatically changes to "Target" when you turn off the range using the toggle switch in the range field.</span>
                <hr />      
                <h6>Markdown</h6>
                <span>This website supports some basic markdown. Surround text in asterisks like *this* to bold it, and surround it in underscores to italicize it, like _this_. You can combine the two to form bolded italics like _*this*_.</span>
                <hr />       
                <h6>JSON Import/Export</h6>
                <span>In addition to saving the card as an image, you can also export the card as JSON. Then, you can import that JSON file to resume editing where you left off.</span>
                <br/><span><b>Note:</b> In order for images (i.e card art) to work with import/export, you must specify the image from a URL. If you upload an image from your computer, you will have to re-upload it every time you import the card from JSON.</span>
                <hr />
                <h6>Sliders</h6>
                <span>You can bypass the minimum and maximum values for sliders by typing any value into the box. Hopefully your assets are sized so that you don't need to do this, though!</span>          
                <hr />
                <h6>Spacers</h6>
                <span>We've included some "spacer" symbols of various sizes (e.g. <code>&#123;spacer_tiny&#125;</code>) that you can use for precise formatting. You can use them to insert tiny amounts of space between symbols, or to leave blank spaces for custom icons that you'd like to add manually with an image editing tool.</span>
            </ToggleBox>
        </Container>
    );
}

export default TutorialPage;