import { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { downloadJSON, getImageFromBase64, getImageFromUrl } from '../Utils';

function CardIOForm({ cardState, ...props }) {
  const [card, setCard] = cardState;
  const [cardUploadError, setCardUploadError] = useState(null);

  function downloadCard() {
    let cardNameCleaned = card.name.replace("\n", " ").replace(/[^A-Za-z0-9 ]/g, '');
    if (!cardNameCleaned)
      cardNameCleaned = "SpiritForge Card";

    // make a shallow copy of card for export, but with a new art object where art.image is
    // a base64 encoded image instead of an Image object. we'll reverse this process when loading cards.
    let imageSrcOrNull = card.art.image ? card.art.image.src : null;
    let artForExport = { ...card.art, image: imageSrcOrNull };
    let cardForExport = { ...card, art: artForExport };
    downloadJSON(cardForExport, `${cardNameCleaned}.json`);
  }

  function setCardFromData(cardData) {
    let importedCard;
    try {
      importedCard = JSON.parse(cardData);
    } catch {
      setCardUploadError("Error: invalid JSON file.");
      return;
    }

    // old card file format stored urls instead of base64 image data. update those files on import.
    let getCardArtImage;
    if (importedCard.art.url) {
      getCardArtImage = getImageFromUrl(importedCard.art.url);
      delete importedCard.art.url;
    } else {
      getCardArtImage = getImageFromBase64(importedCard.art.image)
    }

    getCardArtImage
      .then(loadedImage => {
        importedCard.art.image = loadedImage;
        setCard(importedCard);
      })
      .catch(() => {
        setCardUploadError("Error loading card art from file. Try re-entering the URL in the form, or uploading the art as a file.");
        importedCard.art.image = null;
        setCard(importedCard);
      });
  }

  // similar to ImageForm.js
  function onImportFileChanged(event) {
    setCardUploadError(false);
    const selectedFile = event.target?.files[0];
    if (!selectedFile) // user hit "cancel" in file upload menu
      return;

    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, "UTF-8");
    fileReader.onload = e => setCardFromData(e.target.result);
    fileReader.onerror = () => setCardUploadError(true);
  }

  return (
    <Alert variant="warning" {...props} >
      <Alert.Heading>
        Import Card
      </Alert.Heading>
      <Form.Control type="file" onChange={onImportFileChanged} />
      {cardUploadError}
      <hr style={{ marginBottom: "24px" }} />
      <Alert.Heading>
        Export Card
      </Alert.Heading>
      <Button variant="secondary" onClick={downloadCard}>
        JSON
      </Button>
    </Alert>
  );
}


export default CardIOForm;