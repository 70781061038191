import { Alert, Form } from 'react-bootstrap';

function ToggleBox({title, toggleState, children, minHeight, ...rest}) {

  // passed-in toggleState should contain the toggle value and a callback to update it.
  // undefined toggleState indicates that this box is not toggleable.

  const toggleable = toggleState !== undefined;
  const [toggled, setToggled] = toggleable ? toggleState : [null, null];
  const shouldDisplay = !toggleable || toggled;

  function toggle() {
    return (
      <span style={{ float: "right" }}>
        <Form.Check type="switch" checked={toggled} onChange={(event) => setToggled(!toggled)} />
      </span>
    )
  }

  return (
    <Alert
      style={{ "minHeight": minHeight || "150px" }}
      {...rest}
    >
      <Alert.Heading>
        {title}
        {toggleable && toggle()}
      </Alert.Heading>
      {shouldDisplay && children}
    </Alert >
  );
}

export default ToggleBox;