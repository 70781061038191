import React, { useEffect, useRef } from "react";
import { clearCanvas, createCanvasGlob, createCardArea, createGrowthOptions, createPresenceAreas, drawImageInArea, drawInnatePowers, drawSymbolGroupInArea, drawTrail, drawVariableHeightArea, parseAndWriteTextInArea, simpleDraw, splitIntoLines } from '../CanvasUtils.js';
import { FONT_STYLES } from '../FontStyles.js';
import { getImg } from '../Utils.js';

function BoardCanvas({ board, ...rest }) {
  const scale = 1500;
  const ratio = 0.66560762509;
  const symbolSizeRatio = 1 / 3000;

  const images = window.SpiritForge.images; // see App.js

  const canvasRef = useRef();
  useEffect(renderBoard);

  // this is what the BoardCanvas functional component ultimately returns.
  // all the logic for actually drawing stuff in it is below.
  function boardCanvasElement() {
    return <canvas ref={canvasRef} {...rest} width={scale} height={scale * ratio} />
  }

  function renderBoard() {
    const canvasGlob = createCanvasGlob(canvasRef.current, images, scale, ratio, symbolSizeRatio, "board");
    const presenceGlob = createCanvasGlob(canvasRef.current, images, scale, ratio, 1 / 6000, "presence");
    const growthGlob = createCanvasGlob(canvasRef.current, images, scale, ratio, 1 / 6000, "growth");

    clearCanvas(canvasGlob);
    if (board.spiritImage) {
      drawImageInArea(canvasGlob, getImg(board.spiritImage), null, 0.25 + board.spiritImageOffsetLeft, 0.25 + board.spiritImageOffsetTop, 1.0 + board.spiritImageScale);
    }

    const specialRulesHeight = board.specialRules.specialRulesHeight * 0.97;

    const specialRulesBottom = 0.988;
    const specialRulesArea = createCardArea(canvasGlob, 0.197, specialRulesBottom - specialRulesHeight / 2, 0.371, specialRulesHeight);

    drawVariableHeightArea(canvasGlob, "special_rules", specialRulesArea, specialRulesHeight);

    if (!board.twoGrowthLines) {
      simpleDraw(canvasGlob, "spirit_board");
    } else {
      simpleDraw(canvasGlob, "spirit_board_twogrowth");
    }

    drawTrail(canvasGlob, specialRulesArea, 0.0, 0.0, board.trails[0]);

    var currentRelativeTop = 75 / specialRulesArea.px_height;

    drawImageInArea(canvasGlob, getImg("specialrules"), specialRulesArea, 0.143, currentRelativeTop, 0.28);

    currentRelativeTop = 115 / specialRulesArea.px_height;

    for (let i = 0; i < board.specialRules.rules.length; i++) {
      const rule = board.specialRules.rules[i];
      parseAndWriteTextInArea(canvasGlob, "*" + rule.name.toUpperCase() + "*", FONT_STYLES.SPECIAL_RULES_TITLE, specialRulesArea, 0.015, currentRelativeTop, 1.0);
      currentRelativeTop += FONT_STYLES.SPECIAL_RULES_TITLE.sizeRatio * FONT_STYLES.SPECIAL_RULES_TITLE.lineSpacingRatio * canvasGlob.scale / specialRulesArea.px_height;
      const lines = splitIntoLines(canvasGlob, rule.text);
      for (let j = 0; j < lines.length; j++) {
        const line = lines[j];
        parseAndWriteTextInArea(canvasGlob, line, FONT_STYLES.SPECIAL_RULES_TEXT, specialRulesArea, 0.015, currentRelativeTop, 1.0);
        currentRelativeTop += FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / specialRulesArea.px_height;
      }
      currentRelativeTop += 0.05;
    }

    var innatePowersHeight;
    var innate_areas = [{}, {}];

    if (!board.twoGrowthLines) {
      innatePowersHeight = 0.59;
      innate_areas[0].whole = createCardArea(canvasGlob, 0.532, 0.8, 0.265, 0.37);
      innate_areas[1].whole = createCardArea(canvasGlob, 0.85, 0.8, 0.265, 0.37);
    } else {
      innatePowersHeight = 0.635;
      innate_areas[0].whole = createCardArea(canvasGlob, 0.532, 0.80, 0.265, 0.285);
      innate_areas[1].whole = createCardArea(canvasGlob, 0.85, 0.80, 0.265, 0.285);
    }
    drawImageInArea(canvasGlob, getImg("innatepowers"), null, 0.453, innatePowersHeight, 0.28);

    drawInnatePowers(canvasGlob, board.innatePowers, innate_areas);

    var presenceTracks = [];

    var presenceTracksArea = createCardArea(presenceGlob, 0.683, 0.39, 0.612, 0.333);
    var presenceLabelHeight;

    if (!board.twoGrowthLines) {
      presenceLabelHeight = 0.225;
      drawTrail(canvasGlob, presenceTracksArea, 0.0, 0.24, board.trails[1]);
      drawTrail(canvasGlob, presenceTracksArea, 0.0, 0.71, board.trails[2]);
      presenceTracks[0] = createPresenceAreas(presenceGlob, board.presenceTracks[0].length, presenceTracksArea, 0.01, 0.24, 0.115, 0.009);
      presenceTracks[1] = createPresenceAreas(presenceGlob, board.presenceTracks[1].length, presenceTracksArea, 0.01, 0.72, 0.115, 0.009);
    } else {
      presenceLabelHeight = 0.315;
      drawTrail(canvasGlob, presenceTracksArea, 0.0, 0.48, board.trails[1]);
      drawTrail(canvasGlob, presenceTracksArea, 0.0, 0.90, board.trails[2]);
      presenceTracksArea = createCardArea(presenceGlob, 0.6835, 0.495, 0.612, 0.333);
      presenceTracks[0] = createPresenceAreas(presenceGlob, board.presenceTracks[0].length, presenceTracksArea, 0.01, 0.19, 0.115, 0.009);
      presenceTracks[1] = createPresenceAreas(presenceGlob, board.presenceTracks[1].length, presenceTracksArea, 0.01, 0.59, 0.115, 0.009);
    }

    drawImageInArea(canvasGlob, getImg("presence"), null, 0.432, presenceLabelHeight, 0.27);

    for (let presenceTrackIdx = 0; presenceTrackIdx < board.presenceTracks.length; presenceTrackIdx++) {
      for (let i = 0; i < board.presenceTracks[presenceTrackIdx].length; i++) {
        var marker = board.presenceTracks[presenceTrackIdx][i];
        drawSymbolGroupInArea(presenceGlob, presenceTracks[presenceTrackIdx][i], marker, 0.5, 0.5, 1.0, 0.65);
      }
    }

    drawImageInArea(canvasGlob, getImg(board.growthType), null, 0.47, 0.042, 0.28);

    const growthArea = createCardArea(canvasGlob, 0.68, 0.1, 0.62, 0.18);
    var sizeConfig = createGrowthOptions(growthGlob, growthArea, board.growthOptions[0], 0.08);
    if (board.twoGrowthLines && board.growthOptions.length > 1) {
      const growthArea2 = createCardArea(canvasGlob, 0.68, 0.222, 0.62, 0.18);
      createGrowthOptions(growthGlob, growthArea2, board.growthOptions[1], 0.08, sizeConfig);
    }

    parseAndWriteTextInArea(canvasGlob, board.spiritName, FONT_STYLES.SPIRIT_NAME, specialRulesArea, 0.025, 0.028, 1.0);

    //draw_areas(canvasGlob, [specialRulesArea, innate_areas[0].whole, innate_areas[0].targetingArea, innate_areas[0].speedArea, innate_areas[0].rangeArea, innate_areas[0].targetArea, innate_areas[0].nameArea]);
    //draw_areas(canvasGlob, [innate_areas[0].whole, innate_areas[1].whole]);
    //draw_areas(canvasGlob, [presenceTracksArea]);

    //draw_areas(canvasGlob, presenceTracks[0]);
    //draw_areas(canvasGlob, presenceTracks[1]);
    //draw_areas(canvasGlob, [growthArea]);
    //draw_areas(canvasGlob, growthAreas);
    //draw_areas(canvasGlob, growthOptionAreas[0]);
  }

  return boardCanvasElement();
}

export default BoardCanvas;