import { Form, Row, Col, InputGroup } from "react-bootstrap";

function PercentSliderForm(props) {
  const [value, setValue] = props.valueState;
  const label = props.label;
  const [min, max] = props.range;

  function onValueChanged(event) {
    setValue(event.target.value / 100);
  }

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Row>
        <Col xs={7}>
          <Form.Range value={value * 100} onChange={onValueChanged} min={min} max={max} />
        </Col>
        <Col xs={5}>
          <InputGroup size="sm" className="mb-3">
            <Form.Control type="number" value={(value * 100).toFixed(0)} onChange={onValueChanged} />
            <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}

export default PercentSliderForm;