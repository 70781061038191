import { Image } from 'react-bootstrap';

function ImageToggle(props) {
    // we're ignoring passed-in onClick and draggable. including here just pulls
    // them out of ...otherprops so they don't mess with the spread operator below.
    const { toggled, onToggle, className, onClick, draggable, ...otherProps } = props;

    const toggledClassName = toggled ? className : className + " dimmed";

    return <Image className={toggledClassName} onClick={onToggle} draggable='false' {...otherProps} />
}

export default ImageToggle;