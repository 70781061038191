import { Form } from "react-bootstrap";

function DropdownForm(props) {
  const [value, setValue] = props.valueState;
  const options = props.options;


  function onValueChanged(event) {
    setValue(event.target.value);
  }

  return (
    <Form.Select value={value} onChange={onValueChanged}>
      {options.map((optionName, index) => {
        return (
          <option key={index} value={optionName}>{optionName}</option>
        )
      })}
    </Form.Select>
  );
}

export default DropdownForm;