import { FONT_STYLES } from './FontStyles.js';
import { getImg } from './Utils.js';
import { SYMBOL_GROUP } from './SymbolGroupLayouts.js';

// canvasGlob
// canvas: reference to canvas
// scale (width)
// ratio (height to width ratio)
// total_width (scale)
// total_height (scale * ratio)
// images (array of all images)
function createCanvasGlob(canvas, images, scale, ratio, symbolSizeRatio, mode) {
  const canvasGlob = {
    canvas: canvas,
    scale: scale,
    ratio: ratio,
    total_width: scale,
    total_height: scale * ratio,
    images: images,
    symbolSizeRatio: symbolSizeRatio,
    mode: mode
  }

  return canvasGlob;
}

// clears canvas by drawing a white box over it.
function clearCanvas(canvasGlob) {
  var c = canvasGlob.canvas;
  var ctx = c.getContext("2d");
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvasGlob.total_width, canvasGlob.total_height);
}

// queues up a draw command. This must be used to draw any image that isn't automatically loaded on start.
// typically, card elements won't be loaded, smaller things like icons will be.
function simpleDraw(canvasGlob, img_key, left_px, top_px, px_width, px_height) {
  var c = canvasGlob.canvas;
  var ctx = c.getContext("2d");

  if (!left_px) {
    left_px = 0;
  }
  if (!top_px) {
    top_px = 0;
  }

  if (!px_width) {
    px_width = canvasGlob.total_width;
  }
  if (!px_height) {
    px_height = canvasGlob.total_height;
  }

  //console.log("tryna draw " + img_key + " " + top_px + " " + px_width + " " + px_height);
  ctx.drawImage(getImg(img_key), left_px, top_px, px_width, px_height);
}

function createCardArea(canvasGlob, relativeLeft, relativeTop, relativeWidth, relativeHeight, parentArea) {

  var parentRelativeLeft = 0.5;
  var parentRelativeTop = 0.5;
  var parentRelativeWidth = 1.0;
  var parentRelativeHeight = 1.0;

  if (parentArea) {
    parentRelativeLeft = parentArea.relative_left;
    parentRelativeTop = parentArea.relative_top;
    parentRelativeWidth = parentArea.relative_width;
    parentRelativeHeight = parentArea.relative_height;
  }

  var newRelativeLeft = parentRelativeLeft - parentRelativeWidth / 2 + relativeLeft * parentRelativeWidth;
  var newRelativeWidth = parentRelativeTop - parentRelativeHeight / 2 + relativeTop * parentRelativeHeight;
  var newAreaWidth = parentRelativeWidth * relativeWidth;
  var newAreaHeight = parentRelativeHeight * relativeHeight;

  var new_area = {
    relative_left: newRelativeLeft,
    relative_top: newRelativeWidth,
    relative_width: newAreaWidth,
    relative_height: newAreaHeight,
    px_width: newAreaWidth * canvasGlob.total_width,
    px_height: newAreaHeight * canvasGlob.total_height
  };

  //console.log("new area: ");
  //console.log(new_area);

  return new_area;
}

// draws the card thershold area, based on size value specified in input.
function drawVariableHeightArea(canvasGlob, imgName, area, relative_height) {
  var c = canvasGlob.canvas;
  var ctx = c.getContext("2d");

  var img = getImg(imgName);

  var cornerCoords = getPositionInArea(canvasGlob, area, 0, 0);
  ctx.drawImage(img, 0, 0, img.width, img.height * relative_height, cornerCoords.x, cornerCoords.y, area.px_width, area.px_height);
}

// Get the absolute px coordinates of a relative point in an area.
function getPositionInArea(canvasGlob, area, relative_left, relative_top) {
  var target_left_px = (area.relative_left - area.relative_width / 2 + relative_left * area.relative_width) * canvasGlob.total_width;
  var target_top_px = (area.relative_top - area.relative_height / 2 + relative_top * area.relative_height) * canvasGlob.total_height;

  var output = {
    x: target_left_px,
    y: target_top_px,
  };

  return output;
}

// sets up canvas ctx for writing text based on the input text type. use before measuring or printing text.
// returns vertical spacing amount.
function setupCtxForText(canvasGlob, style, bold, italic, scale_factor) {
  var canvas = canvasGlob.canvas;
  var ctx = canvas.getContext("2d");

  // all individual text pieces will be rendered using middle/center.
  // text block alignment, however, can be whatever.
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = style.color;

  var fontSize = style.sizeRatio * canvasGlob.scale * scale_factor;

  var prefix;
  var weight;

  canvas.style.letterSpacing = style.letterSpacingRatio * canvasGlob.scale + "px";

  if (italic) {
    prefix = style.italicStyle;
  } else {
    prefix = style.normalStyle;
  }

  if (bold) {
    weight = style.boldWeight;
  } else {
    weight = style.normalWeight;
  }

  ctx.font = prefix + " " + weight + " " + fontSize + "px " + style.font;

  var spacing = fontSize * style.lineSpacingRatio;

  return spacing;
}

/*
THE Writetext method. Parses and etc.
*/
function parseAndWriteTextInArea(canvasGlob, text, style, area, relative_left, relative_top, scale_factor, wrapText) {
  var lines = splitIntoLines(canvasGlob, text);

  if (wrapText) {
    let unparsedLineIndex = 0;
    while (unparsedLineIndex < lines.length) {

      let fits = false;
      let newLine = lines[unparsedLineIndex];
      let currentlyWrapped = "";
      while (!fits) {
        let width = getUnparsedLineWidth(canvasGlob, newLine, style, scale_factor);
        const margin = 0.015;
        let lastSpaceIndex = newLine.lastIndexOf(" ");
        if (lastSpaceIndex !== -1 && width / area.px_width > 1.0 - (2 * margin)) {
          currentlyWrapped = newLine.substring(lastSpaceIndex) + currentlyWrapped;
          newLine = newLine.substring(0, lastSpaceIndex);
          // console.log("Doesn't fit. wrapping:" + currentlyWrapped + "!");
        } else {
          fits = true;
          if (currentlyWrapped !== "") {
            lines[unparsedLineIndex] = newLine;
            lines.splice(unparsedLineIndex + 1, 0, currentlyWrapped.substring(1));
            // console.log("Spliced: " + currentlyWrapped);
          }
        }
      }
      unparsedLineIndex++;
    }
  }

  var parsedLines = [];
  for (let i = 0; i < lines.length; i++) {
    parsedLines[i] = parseLineIntoChunks(canvasGlob, lines[i]);
  }

  var lineSpacingPx = setupCtxForText(canvasGlob, style, false, false, scale_factor);

  const relativeLineSpacing = lineSpacingPx / (area.px_height);

  var relativeTopStart;

  if (style.textBaseline === "middle") {
    let numEmptyLines = 0;
    for(let i = 0; i < parsedLines.length; i++) {
      if(parsedLines[i].length === 0) {
        numEmptyLines += 1;
      }
    }
    relativeTopStart = relative_top - (((parsedLines.length - numEmptyLines - 1) / 2) + (numEmptyLines / 4)) * relativeLineSpacing;
  } else {
    relativeTopStart = relative_top;
  }

  let currentRelativeTop = relativeTopStart;
  for (let i = 0; i < parsedLines.length; i++) {
    writeParsedLineInArea(canvasGlob, parsedLines[i], style, area, relative_left, currentRelativeTop, scale_factor);
    if(parsedLines[i].length === 0) {
      currentRelativeTop += relativeLineSpacing * 0.5;
    } else {
      currentRelativeTop += relativeLineSpacing;
    }  
  }
}

// splits text into a list of lines, on \n character.
function splitIntoLines(canvasGlob, text) {
  var nextOccurrence = 0;
  var lines = [];
  while (nextOccurrence !== -1) {
    nextOccurrence = text.indexOf("\n");
    //console.log("Next occurrence of newline is: " + nextOccurrence);
    if (nextOccurrence !== -1) {
      lines[lines.length] = text.substring(0, nextOccurrence);
      text = text.substring(nextOccurrence + 1);
    } else {
      lines[lines.length] = text.substring(0);
    }
  }
  return lines;
}


// For non-symbol chunks, value = the the text.
// for symbol chunks, value = the img key (not including _icon)
function parseLineIntoChunks(canvasGlob, line) {
  var parsedLine = [];
  var currentChunkIndex = 0;

  parsedLine[0] = {
    isText: true,
    isBold: false,
    isItalic: false,
    value: "",
  }

  var currentlyItalic = false;
  var currentlyBold = false;
  var currentlySymbol = false;

  for (let i = 0; i < line.length; i++) {
    let char = line[i];
    if (((char === '*' || char === '_' || char === "{") && !currentlySymbol) || char === "}") {
      // control character - make new chunk.
      if (char === "*" && !currentlySymbol) {
        currentlyBold = !currentlyBold;
      } else if (char === "_" && !currentlySymbol) {
        currentlyItalic = !currentlyItalic;
      } else if ((char === "{" && !currentlySymbol)) {
        currentlySymbol = true;
      } else if ((char === "}" && currentlySymbol)) {
        currentlySymbol = false;
      }

      currentChunkIndex++;
      parsedLine[currentChunkIndex] = {
        isText: !currentlySymbol,
        isBold: currentlyBold,
        isItalic: currentlyItalic,
        value: "",
      }
    } else {
      //normal character, read into current chunk.
      parsedLine[currentChunkIndex].value += line[i];
    }
  }

  var cleanedParsedLine = []

  //clean up empty chunks
  var cleanIdx = 0;
  for (let i = 0; i < parsedLine.length; i++) {
    if (parsedLine[i].value !== "") {
      cleanedParsedLine[cleanIdx] = parsedLine[i];
      cleanIdx++;
    }
  }

  //console.log("Parsed Line:");
  //console.log(cleanedParsedLine);

  return cleanedParsedLine;
}

// writes a parsed line into an area at the given coordinates.
function writeParsedLineInArea(canvasGlob, parsedLine, style, area, relativeLeft, relativeTop, scale_factor) {
  var currentRelativeLeft;

  //console.log("TextAlignment: " + style.textAlignment);
  if (style.textAlignment === "left") {
    currentRelativeLeft = relativeLeft;
  } else {
    //center aligned
    var relativeTotalWidth = getParsedLineWidth(canvasGlob, parsedLine, style, scale_factor) / area.px_width;
    currentRelativeLeft = relativeLeft - relativeTotalWidth / 2;
  }

  for (let i = 0; i < parsedLine.length; i++) {
    var chunk = parsedLine[i];

    var relativeChunkWidth = getParsedChunkWidth(canvasGlob, chunk, style, scale_factor) / area.px_width;

    //console.log("WPL Debug: " + currentRelativeLeft + " " + relativeLeft + " " + relativeChunkWidth + " " + area.px_width);

    currentRelativeLeft += relativeChunkWidth / 2;

    renderParsedChunkInArea(canvasGlob, chunk, style, area, currentRelativeLeft, relativeTop, scale_factor);

    currentRelativeLeft += relativeChunkWidth / 2;
  }
}

// gets the width of a parsed line.
function getParsedLineWidth(canvasGlob, parsedLine, style, scale_factor) {
  var totalWidth = 0;
  for (let i = 0; i < parsedLine.length; i++) {
    totalWidth += getParsedChunkWidth(canvasGlob, parsedLine[i], style, scale_factor);
  }
  return totalWidth;
}

// gets the width of a parsed chunk.
function getParsedChunkWidth(canvasGlob, chunk, style, scale_factor) {
  if (!chunk.isText) {
    var imageName = getParsedSymbolParameters(canvasGlob, chunk.value).symbolName;
    var img = getImg("icon_" + imageName);
    if (img) {
      return img.width * scale_factor * getSymbolSize(canvasGlob, imageName) * canvasGlob.scale;
    } else {
      return 50 * canvasGlob.symbolSizeRatio * canvasGlob.scale;
    }
  } else {
    var canvas = canvasGlob.canvas;
    var ctx = canvas.getContext("2d");
    setupCtxForText(canvasGlob, style, chunk.isBold, chunk.isItalic, scale_factor);

    return ctx.measureText(chunk.value).width;
  }
}

function getSymbolSize(canvasGlob, imageName) {
  if (!window.SpiritForge.symbolSizes) return;
  if (window.SpiritForge.symbolSizes[imageName]) {
    if (window.SpiritForge.symbolSizes[imageName][canvasGlob.mode]) {
      return window.SpiritForge.symbolSizes[imageName][canvasGlob.mode] * canvasGlob.symbolSizeRatio;
    }
  }
  return canvasGlob.symbolSizeRatio;
}

function getUnparsedLineWidth(canvasGlob, unparsedLine, style, scale_factor) {
  var parsedLine = parseLineIntoChunks(canvasGlob, unparsedLine);
  return getParsedLineWidth(canvasGlob, parsedLine, style, scale_factor);
}


function renderParsedChunkInArea(canvasGlob, chunk, style, area, relativeLeft, relativeTop, scale_factor) {
  if (chunk.isText) {

    /*
    //text bounding box debug
    var width = getParsedChunkWidth(canvasGlob, chunk, style, scale_factor);
    var ctx = canvasGlob.canvas.getContext("2d");
    var textCorner = getCoordinatesForRelativeCorner(canvasGlob, area, relativeLeft, relativeTop, width, 20);
    ctx.rect(textCorner.x, textCorner.y + width/70, width, 20); 
    ctx.stroke();
    */

    renderTextChunkInArea(canvasGlob, chunk.value, style, chunk.isBold, chunk.isItalic, area, relativeLeft, relativeTop, scale_factor);
  } else {
    // symbol
    var symbolParams = getParsedSymbolParameters(canvasGlob, chunk.value);
    var symbolName = symbolParams.symbolName;
    var params = symbolParams.params;

    renderSymbolChunkInArea(canvasGlob, symbolName, style, area, relativeLeft, relativeTop, scale_factor);

    if (params === "presence") {
      renderSymbolChunkInArea(canvasGlob, "presence_overlay", style, area, relativeLeft, relativeTop, scale_factor);
    } else if (params === "no") {
      renderSymbolChunkInArea(canvasGlob, "no", style, area, relativeLeft, relativeTop, scale_factor);
    } else {
      var options = getRangeTextOptions(canvasGlob, symbolName);
      if (symbolName === "range" || symbolName === "move_range") {
        let rangeFont = FONT_STYLES.RANGE;
        if (canvasGlob.mode === "growth") {
          // thanks, less than games
          rangeFont = FONT_STYLES.GROWTH_RANGE;
        }
        if (canvasGlob.mode === "presence") {
          rangeFont = FONT_STYLES.PRESENCE_RANGE;
        }
        let extraVerticalOffset = -6;

        let leftPxOffset = -0.25 * canvasGlob.symbolSizeRatio * canvasGlob.scale * scale_factor;
        let topPxOffset = (extraVerticalOffset + options.verticalOffset) * canvasGlob.symbolSizeRatio * canvasGlob.scale * scale_factor;

        renderTextChunkInArea(canvasGlob, params, rangeFont, false, false, area, relativeLeft + leftPxOffset / area.px_width, relativeTop + topPxOffset / area.px_height, options.scale * scale_factor * canvasGlob.symbolSizeRatio * 850);
      } else if (symbolName === "energy" || symbolName === "cardplays") {
        renderTextChunkInArea(canvasGlob, params, FONT_STYLES.ENERGY, false, false, area, relativeLeft, relativeTop, options.scale * scale_factor * canvasGlob.symbolSizeRatio * 850)
      }
    }
  }
}

function getRangeTextOptions(canvasGlob, symbolName) {
  var output = { scale: 1.0, verticalOffset: 0.0 };
  if (symbolName === "range" || symbolName === "move_range") {
    if (canvasGlob.mode === "growth") {
      output.scale = 3.0;
    }

    if (symbolName === "move_range") {
      output.verticalOffset = -27;
    }
  } else if (symbolName === "energy" || symbolName === "cardplays") {
    if (canvasGlob.mode === "growth") {
      output.scale = 0.8;
    }
  }

  return output;
}

function getParsedSymbolParameters(canvasGlob, value) {
  var commaPos = value.indexOf(',');
  var symbolName;
  var params;

  if (commaPos !== -1) {
    symbolName = value.substring(0, commaPos);
    params = value.substring(commaPos + 1);
  } else {
    symbolName = value.substring(0);
  }

  var output = {
    symbolName: symbolName,
    params: params
  };

  return output;
}

function renderTextChunkInArea(canvasGlob, text, style, isBold, isItalic, area, relativeLeft, relativeTop, scale_factor) {
  var canvas = canvasGlob.canvas;
  var ctx = canvas.getContext("2d");
  // text

  setupCtxForText(canvasGlob, style, isBold, isItalic, scale_factor);

  var coords = getPositionInArea(canvasGlob, area, relativeLeft, relativeTop);

  var correctedHeight = coords.y;
  if (style.verticalCorrectionRatio) {
    var rangeSymbolMultiplier = 1.0; // extra multiplier since range numbers should be scaled like symbols
    if (style === FONT_STYLES.RANGE || style === FONT_STYLES.ENERGY) {
      rangeSymbolMultiplier = 850 * canvasGlob.symbolSizeRatio;
    }
    correctedHeight = coords.y + style.verticalCorrectionRatio * style.sizeRatio * canvasGlob.scale * rangeSymbolMultiplier;
  }

  let strokeColor = style.strokeColor;
  if (strokeColor) {
    ctx.strokeStyle = 'black';
    ctx.lineWidth = canvasGlob.scale * style.sizeRatio * 0.125;
    ctx.strokeText(text, coords.x, correctedHeight);
  }

  if(style.autoCaps)
  {
    text = text.toUpperCase();
  }

  ctx.fillText(text, coords.x, correctedHeight);

  //debug for middle alignment check
  //ctx.fillRect(0, coords.y - 1, 30, 2);

  //debug for horizontal alignment check
  //ctx.fillRect(coords.x - 1, correctedHeight - 15, 2, 30);

}

function renderSymbolChunkInArea(canvasGlob, symbolName, style, area, relativeLeft, relativeTop, scale_factor) {
  var canvas = canvasGlob.canvas;
  var ctx = canvas.getContext("2d");
  var symbol = getImg("icon_" + symbolName);

  if (!symbol) return;

  var pxImgDimensions = {
    x: symbol.width * scale_factor * getSymbolSize(canvasGlob, symbolName) * canvasGlob.scale,
    y: symbol.height * scale_factor * getSymbolSize(canvasGlob, symbolName) * canvasGlob.scale
  };

  //console.log("Drawing symbol: " + relativeLeft + ", " + relativeTop + ", " + pxImgDimensions.x + ", " + pxImgDimensions.y);
  var symbolCoords = getCoordinatesForRelativeCorner(canvasGlob, area, relativeLeft, relativeTop, pxImgDimensions.x, pxImgDimensions.y);

  ctx.drawImage(symbol, symbolCoords.x, symbolCoords.y, pxImgDimensions.x, pxImgDimensions.y);
}

/* 
Given the relative coordinates of the center of a box in an area, and the pixel sizes of that box,
returns the px coordinates of the upper left corner of that box.

Useful for positioning pictures when you only have relative coords of the center.
*/
function getCoordinatesForRelativeCorner(canvasGlob, area, relative_left, relative_top, pixel_width, pixel_height) {

  // center of the target, in px
  //console.log("Accessing area: ");
  //console.log(area);

  if (!area) {
    area = {
      relative_left: 0.5,
      relative_top: 0.5,
      relative_width: 1.0,
      relative_height: 1.0
    }
  }

  var center_left_px = (area.relative_left - area.relative_width / 2 + relative_left * area.relative_width) * canvasGlob.total_width;
  var center_top_px = (area.relative_top - area.relative_height / 2 + relative_top * area.relative_height) * canvasGlob.total_height;

  var final_left_px = center_left_px - pixel_width / 2;
  var final_top_px = center_top_px - pixel_height / 2;

  //console.log(final_left_px + ", " + final_top_px);

  var output = {
    x: final_left_px,
    y: final_top_px,
  };

  return output;
}

// debug that draws a box on each area.
// called in after_draw.
// todo, label the areas?
function draw_areas(canvasGlob, areas) {
  for (let i = 0; i < areas.length; i++) {
    var area = areas[i];
    const pixel_width = area.relative_width * canvasGlob.total_width;
    const pixel_height = area.relative_height * canvasGlob.total_height;

    var offsetCoords = getCoordinatesForRelativeCorner(canvasGlob, area, 0.5, 0.5, pixel_width, pixel_height);

    var c = canvasGlob.canvas;
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.rect(offsetCoords.x, offsetCoords.y, pixel_width, pixel_height);
    ctx.stroke();
  }
}

function drawImageInArea(canvasGlob, image, area, relativeLeft, relativeTop, scale_factor) {
  var pxWidth = image.width * scale_factor;
  var pxHeight = image.height * scale_factor;

  var cornerCoords = getCoordinatesForRelativeCorner(canvasGlob, area, relativeLeft, relativeTop, pxWidth, pxHeight);

  var c = canvasGlob.canvas;
  var ctx = c.getContext("2d");
  ctx.drawImage(image, cornerCoords.x, cornerCoords.y, pxWidth, pxHeight);
}

function drawInnatePowers(canvasGlob, innatePowers, innate_areas) {

  for (let i = 0; i < innatePowers.length; i++) {
    const targetingAreaScale = 0.55;
    let targetingAreaPxHeight = 50;
    innate_areas[i].targetingArea = createCardArea(canvasGlob, 0.5, targetingAreaPxHeight / innate_areas[i].whole.px_height,
      getImg("innate_power_targeting").width * targetingAreaScale / innate_areas[i].whole.px_width,
      getImg("innate_power_targeting").height * targetingAreaScale / innate_areas[i].whole.px_height,
      innate_areas[i].whole);
    innate_areas[i].nameArea = createCardArea(canvasGlob, 0.5, 0.014, 1.0, 0.06, innate_areas[i].whole);
    innate_areas[i].speedArea = createCardArea(canvasGlob, 0.145, 0.65, 0.285, 0.65, innate_areas[i].targetingArea);
    innate_areas[i].rangeArea = createCardArea(canvasGlob, 0.45, 0.65, 0.325, 0.65, innate_areas[i].targetingArea);
    innate_areas[i].targetArea = createCardArea(canvasGlob, 0.805, 0.65, 0.382, 0.65, innate_areas[i].targetingArea);

    var targetingImage;
    if (innatePowers[i].targetType === "target") {
      targetingImage = "innate_power_targeting";
    } else {
      // target_land
      targetingImage = "innate_power_targeting_land";
    }
    drawImageInArea(canvasGlob, getImg(targetingImage), innate_areas[i].targetingArea, 0.5, 0.5, targetingAreaScale);

    parseAndWriteTextInArea(canvasGlob, innatePowers[i].name, FONT_STYLES.SPECIAL_RULES_TITLE, innate_areas[i].nameArea, 0.0, 0.5, 1.0);

    drawImageInArea(canvasGlob, getImg("innate_" + innatePowers[i].speed), innate_areas[i].speedArea, 0.5, 0.5, 0.8);

    parseAndWriteTextInArea(canvasGlob, innatePowers[i].range, FONT_STYLES.EFFECT, innate_areas[i].rangeArea, 0.5, 0.5, 1.4);

    parseAndWriteTextInArea(canvasGlob, innatePowers[i].target, FONT_STYLES.EFFECT, innate_areas[i].targetArea, 0.5, 0.5, 1.4);
    //drawImageInArea(innatePowers[i].speed, )

    var currentRelativeTop = 100 / innate_areas[i].whole.px_height;

    if (innatePowers[i].topReminder !== "") {
      var firstReminderLines = splitIntoLines(canvasGlob, innatePowers[i].topReminder);
      for (let j = 0; j < firstReminderLines.length; j++) {
        parseAndWriteTextInArea(canvasGlob, firstReminderLines[j], FONT_STYLES.SPECIAL_RULES_TEXT, innate_areas[i].whole, 0.0, currentRelativeTop, 1.0);
        currentRelativeTop += FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / innate_areas[i].whole.px_height;
      }
    } else {
      currentRelativeTop += 0.02;
    }

    currentRelativeTop += 0.03;

    for (let j = 0; j < innatePowers[i].thresholds.length; j++) {
      var threshold = innatePowers[i].thresholds[j];
      var thresholdTextLines = splitIntoLines(canvasGlob, threshold.effect);

      var relativeTopOffset = 0;
      if (threshold.flushText && thresholdTextLines.length >= 3 && !threshold.forceTopAlign) {
        currentRelativeTop += (thresholdTextLines.length / 2 - 1) * FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / innate_areas[i].whole.px_height;
        relativeTopOffset = -1 * (thresholdTextLines.length / 2 - 0.5) * FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / innate_areas[i].whole.px_height;
      } else if (thresholdTextLines.length > 1) {
        relativeTopOffset = -0.5 * FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / innate_areas[i].whole.px_height;;
      }

      const thresholdScale = 1.5;
      parseAndWriteTextInArea(canvasGlob, threshold.condition, FONT_STYLES.INNATE_THRESHOLD, innate_areas[i].whole, 0.01, currentRelativeTop, thresholdScale);

      currentRelativeTop += relativeTopOffset;

      //pretend the threshold is all of a single element, to get rid of width variance.
      let reg = /{[^}]*}/g;
      let invariantThresholdCondition = threshold.condition.replaceAll(reg, "{water}");

      const thresholdWidth = getUnparsedLineWidth(canvasGlob, invariantThresholdCondition, FONT_STYLES.INNATE_THRESHOLD, thresholdScale) / innate_areas[i].whole.px_width + 0.04;


      for (let k = 0; k < thresholdTextLines.length; k++) {
        var line = thresholdTextLines[k];
        var relativeLeft = thresholdWidth;
        if (k > 1 && !threshold.flushText) {
          relativeLeft = 0;
        }
        parseAndWriteTextInArea(canvasGlob, line, FONT_STYLES.SPECIAL_RULES_TEXT, innate_areas[i].whole, relativeLeft, currentRelativeTop, 1.0);
        currentRelativeTop += FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / innate_areas[i].whole.px_height;
      }

      if (thresholdTextLines.length > 1) {
        currentRelativeTop += 0.05;
      } else {
        currentRelativeTop += 0.105;
      }
    }
    currentRelativeTop -= 0.04;

    if (innatePowers[i].bottomReminder !== "") {
      var secondReminderLines = splitIntoLines(canvasGlob, innatePowers[i].bottomReminder);
      for (let j = 0; j < secondReminderLines.length; j++) {
        parseAndWriteTextInArea(canvasGlob, secondReminderLines[j], FONT_STYLES.SPECIAL_RULES_TEXT, innate_areas[i].whole, 0.0, currentRelativeTop, 1.0);
        currentRelativeTop += FONT_STYLES.SPECIAL_RULES_TEXT.sizeRatio * FONT_STYLES.SPECIAL_RULES_TEXT.lineSpacingRatio * canvasGlob.scale / innate_areas[i].whole.px_height;
      }
    }
  }
}

function createPresenceAreas(canvasGlob, num, area, relativeLeftStart, relativeTop, relativeWidthPer, relativeHorizontalGap) {
  var presenceAreas = [];
  for (let i = 0; i < num; i++) {
    var relativeLeft = relativeLeftStart + i * (relativeWidthPer + relativeHorizontalGap) + relativeWidthPer / 2;
    var pixelDimensions = relativeWidthPer * area.px_width;

    var image;

    if (i === 0) {
      image = getImg("presence_track_start");
    } else {
      image = getImg("presence_track_empty");
    }
    drawImageInArea(canvasGlob, image, area, relativeLeft, relativeTop, pixelDimensions / image.width);

    var relativeHeightPer = pixelDimensions / area.px_height;

    presenceAreas[i] = createCardArea(canvasGlob, relativeLeft, relativeTop, relativeWidthPer, relativeHeightPer, area);
  }
  return presenceAreas;
}

function createGrowthOptions(canvasGlob, area, options, relativeWidthPer, sizeConfig) {
  var symbolAreas = []
  var baseWidth = relativeWidthPer;

  var totalRelativeWidth = 0;

  var totalOptions = 0;

  for (let growthOptionIdx = 0; growthOptionIdx < options.length; growthOptionIdx++) {
    let growthOption = options[growthOptionIdx];
    for (let i = 0; i < growthOption.length; i++) {
      let symbolGroup = growthOption[i];
      let widthOverride = SYMBOL_GROUP[symbolGroup.layout].width_override;
      if (widthOverride != null) {
        totalRelativeWidth += baseWidth * widthOverride;
      } else {
        totalRelativeWidth += baseWidth;
      }

      totalOptions++;
    }
  }

  var remainingSpace = 0.95 - totalRelativeWidth;
  var marginSize = remainingSpace / (totalOptions + options.length);
  var initialLeft = 0.5129 - (totalRelativeWidth + (totalOptions + options.length) * marginSize) / 2

  if (sizeConfig) {
    marginSize = sizeConfig.marginSize;
    initialLeft = sizeConfig.initialLeft;
  }

  var currentLeft = initialLeft;
  for (let growthOptionIdx = 0; growthOptionIdx < options.length; growthOptionIdx++) {
    let growthOption = options[growthOptionIdx];

    if (growthOptionIdx !== 0) {
      // draw growth divider
      //drawImageInAreaRelative(window.getImg("growth_divider"), "growth", current_left, relative_top, 0.25);
      drawImageInArea(canvasGlob, getImg("growth_divider"), area, currentLeft, 0.55, 0.22);
    }
    currentLeft += marginSize;

    for (let i = 0; i < growthOption.length; i++) {
      let symbolGroup = growthOption[i];
      var width = baseWidth;
      let widthOverride = SYMBOL_GROUP[symbolGroup.layout].width_override;
      if (widthOverride != null) {
        width *= widthOverride;
      }

      currentLeft += width / 2;

      var heightOfRelativeWidth = baseWidth * area.px_width / (area.px_height);

      var newArea = createCardArea(canvasGlob, currentLeft, 0.5, width, heightOfRelativeWidth, area);
      symbolAreas.push(newArea);

      drawSymbolGroupInArea(canvasGlob, newArea, symbolGroup, 0.5, 0.5, 1.0, 0.65);

      currentLeft += width / 2;
      currentLeft += marginSize;
    }
  }

  sizeConfig = {
    marginSize: marginSize,
    initialLeft: initialLeft
  }

  return sizeConfig;
}

function drawSymbolGroupInArea(canvasGlob, area, symbolGroup, relativeLeft, relativeTop, scale_factor, labelRelativeTop) {
  var symbols = symbolGroup.symbols;
  var layout = SYMBOL_GROUP[symbolGroup.layout];

  if (symbolGroup.forcedBackground) {
    renderSymbolChunkInArea(canvasGlob, symbolGroup.forcedBackground, null, area, relativeLeft, relativeTop, scale_factor);
  }

  for (let i = 0; i < symbols.length; i++) {
    var symbol = symbols[i];
    const elementStyle = layout.symbols[i]


    let scale = symbol.scale;
    let offsetTop = symbol.offsetTop;
    let offsetLeft = symbol.offsetLeft;
    if (!scale) scale = 1.0;
    if (!offsetTop) offsetTop = 0.0;
    if (!offsetLeft) offsetLeft = 0.0;

    var componentRelativeTop = 0;
    if (i === 0) {
      componentRelativeTop += labelRelativeTop;
    }
    componentRelativeTop += elementStyle.relativeTop + offsetTop;

    parseAndWriteTextInArea(canvasGlob, symbol.value, FONT_STYLES.LATO_LABEL, area, elementStyle.relativeLeft + offsetLeft, componentRelativeTop, scale_factor * elementStyle.scale * scale);
  }
}

function drawTrail(canvasGlob, area, relativeLeft, relativeTop, trailInfo) {
  let image = getImg(trailInfo.image);

  if (!image) return;

  let pxWidth = image.width * trailInfo.scale * trailInfo.widthMultiplier;
  let pxHeight = image.height * trailInfo.scale;

  let coords = getCoordinatesForRelativeCorner(canvasGlob, area, relativeLeft, relativeTop + trailInfo.relativeTopOffset, 0, pxHeight);

  var c = canvasGlob.canvas;
  var ctx = c.getContext("2d");
  ctx.drawImage(image, coords.x, coords.y, pxWidth, pxHeight);
}

export {
  createCanvasGlob, clearCanvas, createCardArea, simpleDraw,
  parseAndWriteTextInArea, drawVariableHeightArea, draw_areas,
  splitIntoLines, drawImageInArea, getUnparsedLineWidth, drawInnatePowers,
  createPresenceAreas, drawSymbolGroupInArea, createGrowthOptions, drawTrail
};

