import { Form } from 'react-bootstrap';

function ToggleForm(props) {

  // passed-in toggleState should contain the toggle value and a callback to update it.
  // undefined toggleState indicates that this box is not toggleable.
  const toggleable = props.toggleState !== undefined;
  const [toggled, setToggled] = toggleable ? props.toggleState : [null, null];

  function toggle() {
    return (
      <Form.Check type="switch" checked={toggled} onChange={(event) => setToggled(!toggled)} />
    )
  }

  return (
    <>
    {toggle()}
    </>
  );
}

export default ToggleForm;